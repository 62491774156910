import {useEffect} from 'react';
import '../styles/globals.css'
import 'nprogress/nprogress.css'
import type { AppProps } from 'next/app'
import NProgress from 'nprogress'
import Router from 'next/router'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect( () => {
    const handleRouteStart = () => NProgress.start()
    const handleRouterDone = () => NProgress.done()

    Router.events.on('routeChangeStart', handleRouteStart)
    Router.events.on('routeChangeComplete', handleRouterDone)
    Router.events.on('routeChangeError', handleRouterDone)

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart)
      Router.events.off('routeChangeComplete', handleRouterDone)
      Router.events.off('routeChangeError', handleRouterDone)
    }

  },[] )
  return <Component {...pageProps} />
}

export default MyApp
